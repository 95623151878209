.popup {
  position: absolute;
  
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}

.popup-question {
  font-size: 17px;
   font-weight: bold;
   text-transform: uppercase;
   word-wrap: break-word;

}

@media screen and (max-width: 600px) {
  .popup-question {
  text-shadow: 0 8px 11px rgb(0 0 0 / 0.9);

  }

  .popup-content .prize-text {
    font-size: 17px;
    font-weight: bold;
    text-shadow: 0 16px 11px rgb(0 0 0 / 0.9);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
    }
    .popup-content  .options button {
      font-size: 0.7rem;
    }


}

.countdown{
  position: absolute;
  top: 20px;
  padding-top: -20px;
}


.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  border-radius: 30px;
}

.popup-content {
  text-align: center;
  color: #fff;
  z-index: 1;
  width: 95%;
}


.popup-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.popup-content .options {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin: auto;
}

 
.popup-content .options button {
  display: table-cell;
  width: 1%;
  white-space: nowrap;
}


.image-options {
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  cursor: pointer;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.9);
}


 .popup-content  .options button {
  width: 80%;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  background-color: rgba(365, 365, 365, 0.8);
  color: #000;
  border: none;
  border-radius: 100px 100px 100px 100px;
  cursor: pointer;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.9);
  font-weight: bold;
}
@media screen and (min-width: 601px) {
.popup-content .prize-text {
  font-size: 17px;
  font-weight: bold;
  text-shadow: 0 16px 11px rgb(0 0 0 / 0.9);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-top: 100px;
  padding: 0;
  }
}

/* CSS for answer options */
.options button {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  background-color: #f4f4f4;
  border: none;
  position: relative;
}

.options button .answer-circle {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-color: rgb(32, 32, 32);
  background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 10px 10px, 10px 10px, 10px 5px;
  background-position: 0px 0px, 5px 5px, 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    position: absolute;
    right: -14px;
    top: -3px;
}

.seconds {
  width: 30px;
  height: 30px;
  border: 3px solid white;
  border-radius: 50px;
  padding: 10px;
  font-size: 25px;
  font-weight: bolder;
}
.value{
  font-size: 19px;
}

@keyframes grow {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

.value {

  animation: 10s grow ease-out;
}