.welcome-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
}

@media screen and (min-width: 1024px) {
  .welcome-overlay {
    border: 1px solid black;
  }
}

.welcome-content {
  z-index: 1;
  text-align: center;
  color: #fff;
  filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.21));
  position: absolute;
  display: flex;
  flex-direction: column;
  place-content: space-around;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}

.win-content {
  z-index: 1;
  text-align: center;
  color: #fff;
  filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.21));
}

.glass2 {
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.51);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.65);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 70%;
}

.win-content button,
.clickhere {
  font-size: 22px;
  background-color: black;
  border-radius: 10px;
  padding: 8px 40px;
  width: fit-content;
  color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;
  margin: 20px auto;
  width: 200px;
}

.welcome-content h1 {
  font-size: 1.6rem;
  margin: 10px 0;
}

.welcome-content button,
.clickhere {
  font-size: 22px;
  background-color: rgb(32, 32, 32);
  background-image: linear-gradient(
    to right,
    #e80303,
    #fe7373
  ); /* Horizontal gradient */
  background-position: 0px 0px, 5px 5px, 0px 0px;
  border-radius: 50px;
  padding: 8px 40px;
  width: fit-content;
  color: white;
  border: none;
  font-weight: 550;
  cursor: pointer;
  margin: auto;
}

.logo2 {
  z-index: 2;
  bottom: 30px;
  position: absolute;

  max-width: 120px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 90%;
  margin: 0 auto;
}

.welcome-content .div {
  max-width: 60%;
  width: 300px;
  margin-bottom: 192px;
  display: flex;
  justify-content: center;
}

.line {
  width: 70%;
  margin: 0 auto;
}

@keyframes scale {
  50% {
    scale: 0.9;
  }
}

.clip-path-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(16% 17%, 79% 18%, 100% 100%, 0% 100%);
  background-color: #f00;
  /* Adjust as needed */
  z-index: 1;
}

.rounded-element {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* Adjust as needed */
  background-color: #fff;
  /* Adjust as needed */
  z-index: 2;
}

/*transfer this css to endscreen.css page */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form label {
  font-weight: bold;
  font-size: 15px;
  color: white;
}

form input {
  font-size: 17px;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #ccc;
  text-align: center;
  width: 182px;
}

form button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 200px;
}

.clickhere {
  margin: 20px auto;
}
