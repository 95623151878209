.win-screen {
  position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 100px;
}

.win-layout {
  display: flex;
  margin-top: 85px;
  flex-direction: column;
  justify-content: center;
  align-items: center;  /* If you also want to center items vertically */
}

.win-layout2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  /* If you also want to center items vertically */
  height: 72vh;
}

.win-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 30px;
}



.welcome-content {
    z-index: 1;
    text-align: center;
    color: #fff;
    filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.21));
    position: absolute;
    bottom: 15%;
}

.win-content {
  z-index: 1;
  text-align: center;
  color: #fff;
  filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.21));

}

.glass2 {
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.51);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.65);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-right: auto;
  margin-left: auto;
  width:70%;

}

.win-content button ,.clickhere{
  font-size: 22px;
  background-color: white;
  border-radius: 10px;
  padding: 8px 40px ;
  width: fit-content;
  color: black;
  border: none;
  font-weight: bold;
  cursor: pointer;
  margin: 20px auto;
  width: 200px;

}

.welcome-content h1 {
    font-size: 1.6rem;
    margin: 10px 0;
}



.logo {
  position: absolute;
  top: 34px;
  max-width: 200px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 2;
  width: fit-content;
}
.content {
  display: flex;
  flex-direction: column;
  width: 300px;
  max-width: 90%;
  margin: 0 auto;
}
.welcome-content  .div{
    max-width: 60%;
    width: 300px;
    margin-bottom: 192px;
}
.contentimg{
}

.line{
    width:  70%;
    margin: 0 auto;
}
@keyframes scale {
    50%{
        scale: 0.9;
    }
}

.clip-path-element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(16% 17%, 79% 18%, 100% 100%, 0% 100%);
    background-color: #f00; /* Adjust as needed */
    z-index: 1;
  }
  
  .rounded-element {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px; /* Adjust as needed */
    background-color: #fff; /* Adjust as needed */
    z-index: 2;
  }
  
  
  
  /*transfer this css to endscreen.css page */
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  form label {
    font-weight: bold;
    font-size: 15px;
    color: white;
  }
  
  form input {
    font-size: 17px;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #ccc;
    text-align: center;
    width: 182px;
  }
  
  form button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 200px;
  }
  
  
  .clickhere{
    margin: 20px auto;
  }

  .nextButton {
    background-color: white;
    background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
    background-size: 10px 10px, 10px 10px, 10px 5px;
    margin-top: 10px;
    border-radius: 50px;
    font-size: 18px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }

  .playerReaction {
    position: fixed;
    bottom: 0;
    max-width: 85vw;
    pointer-events: none;
  }