.message {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    font-size: 22px;
    padding: 8px;
    border-radius: 20px;
    margin: 10px 30px;
    color: white;
    text-shadow: 1px 1px 2px black;
}